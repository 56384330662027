<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-1">
        If you roll 2 standard dice that are numbered from 1 to 6, how many different ways are there
        to roll the dice such that their sum is equal to
        <integer-value :value="diceRoll" />?
      </p>

      <v-col class="d-flex mt-0 mb-0" cols="12" sm="4">
        <v-select v-model="inputs.numWays" :items="items" label="Number of Ways" outlined />
      </v-col>

      <p class="mb-3 mt-0">
        What is the entropy associated with a roll of
        <integer-value :value="diceRoll" />?
      </p>

      <calculation-input
        v-model="inputs.entropy"
        dense
        class="mb-4"
        prepend-text="$\text{S}:$"
        append-text="$\text{J/K}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import IntegerValue from '@/tasks/components/IntegerValue';

export default {
  name: 'Question274',
  components: {
    IntegerValue,
    CalculationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        numWays: null,
        entropy: null,
      },
      items: [
        '1',
        '2',
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '20',
        '21',
        '22',
        '23',
        '24',
        '25',
        '26',
        '27',
        '28',
        '29',
        '30',
        '31',
        '32',
        '33',
        '34',
        '35',
        '36',
      ],
    };
  },
  computed: {
    diceRoll() {
      return this.taskState.getValueBySymbol('diceRoll').content;
    },
  },
};
</script>
